/* General Layout */
.coding-activity-view {
  background-color: var(--background-color-light);
  color: var(--text-color-light); /* Ensure clear text contrast */
  transition:
    background-color 0.3s,
    color 0.3s;
}

body.night-mode .coding-activity-view {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* Specific styles for the <pre> tag */
pre {
  background-color: var(
    --card-background-color-dark
  ); /* Light mode background for code blocks */
  color: var(--light-text); /* Ensure code is readable */
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto; /* Allow horizontal scrolling for long code */
  font-size: 14px;
  border: 1px solid var(--light-shadow); /* Light mode border */
}

/* Specific styles for the <pre> tag */
body.night-mode pre {
  background-color: var(
    --card-background-color-light
  ); /* Light mode background for code blocks */
  color: var(--dark-text); /* Ensure code is readable */
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto; /* Allow horizontal scrolling for long code */
  font-size: 14px;
  border: 1px solid var(--light-shadow); /* Light mode border */
}

input.form-control {
  background-color: var(
    --input-background-light
  ); /* Light mode input background */
  color: var(--text-color-dark); /* Ensure text is readable */
}

body.night-mode input.form-control {
  background-color: var(
    --input-background-dark
  ); /* Dark mode input background */
  color: var(--text-color-light); /* Ensure text is readable */
}

thead tr {
  background-color: var(
    --table-header-background-light
  ); /* Light mode header background */
  color: var(--text-color-dark); /* Header text color */
}

tbody tr {
  background-color: var(
    --table-row-background-light
  ); /* Light mode row background */
  color: var(--text-color-dark); /* Row text color */
}

body.night-mode thead tr {
  background-color: var(
    --table-header-background-dark
  ); /* Dark mode header background */
  color: var(--text-color-light); /* Header text color in dark mode */
}

body.night-mode tbody tr {
  background-color: var(
    --table-row-background-dark
  ); /* Dark mode row background */
  color: var(--text-color-light); /* Row text color in dark mode */
}

.instructions-sidebar {
  overflow-y: auto; /* Allow scrolling in the sidebar */
  max-height: 85vh; /* Ensure sidebar height does not exceed viewport height */
  padding-right: 1rem;
  background-color: var(--sidebar-background-light); /* Light mode background */
  color: var(--text-color-dark); /* Light mode text color */
  transition:
    background-color 0.3s,
    color 0.3s;
}

body.night-mode .instructions-sidebar {
  background-color: var(--sidebar-background-dark); /* Dark mode background */
  color: var(--text-color-light); /* Dark mode text color */
}

.instructions-content {
  padding-right: 15px;
}

.current-instruction .accordion-header {
  background-color: #f0ad4e; /* Highlight color for current instruction */
}

.editor-container {
  overflow-y: auto; /* Allows vertical scrolling only if needed */
  overflow-x: hidden; /* Hides horizontal scrolling */
  height: 100%; /* Ensures the editor takes full height of the container */
  border: 1px solid var(--shadow-light); /* Add light shadow for better contrast */
  transition: border-color 0.3s;
}

body.night-mode .editor-container {
  border: 1px solid var(--shadow-dark); /* Adjust border for dark mode */
}

.code-editor-section,
.output-section {
  background-color: var(--editor-background-light); /* Light mode background */
  color: var(--text-color-dark);
  transition:
    background-color 0.3s,
    color 0.3s;
  height: 100vh; /* Ensure these sections take the full height of the viewport */
  overflow: hidden; /* Disable scrolling */
}

body.night-mode .code-editor-section,
body.night-mode .output-section {
  background-color: var(--editor-background-dark); /* Dark mode background */
  color: var(--text-color-light); /* Dark mode text color */
}

.output-card {
  overflow-y: auto; /* Allows vertical scrolling only if needed */
  height: 69vh; /* Set the height of the output card */
  background-color: var(
    --card-background-light
  ); /* Light mode card background */
  border: 1px solid var(--text-color-dark); /* Ensure border visibility */
  color: var(--text-color-dark); /* Text color */
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;
}

body.night-mode .output-card {
  background-color: var(--card-background-dark); /* Dark mode card background */
  border: 1px solid var(--text-color-light); /* Dark mode border */
  color: var(--text-color-light); /* Text color in dark mode */
}

/* Accordion Styling */
.accordion-item {
  background-color: var(
    --accordion-background-light
  ); /* Light mode accordion background */
  border: 1px solid var(--text-color-dark); /* Border color in light mode */
}

body.night-mode .accordion-item {
  background-color: var(
    --accordion-background-dark
  ); /* Dark mode accordion background */
  border: 1px solid var(--text-color-light); /* Border color in dark mode */
}

.accordion-header {
  color: var(--primary-color); /* Use primary color for better visibility */
}

.accordion-body {
  background-color: var(--accordion-body-background-light);
  color: var(--text-color-dark); /* Ensure the body text is clearly visible */
}

body.night-mode .accordion-header {
  color: var(--primary-color); /* Adjust header color for dark mode */
}

body.night-mode .accordion-body {
  background-color: var(
    --accordion-body-background-dark
  ); /* Dark mode accordion body background */
  color: var(--text-color-light); /* Dark mode text color */
}

/* Button Styling */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-color-light); /* Ensure text is visible on the button */
}

.btn-warning {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--text-color-light); /* Ensure text is visible on the button */
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--text-color-light); /* Ensure text is visible on the button */
}

.btn-primary:hover,
.btn-warning:hover,
.btn-secondary:hover {
  filter: brightness(
    0.85
  ); /* Darken hover state slightly for better contrast */
}

body.night-mode .btn-primary,
body.night-mode .btn-warning,
body.night-mode .btn-secondary {
  filter: brightness(
    1.15
  ); /* Lighten buttons slightly in dark mode on hover for better contrast */
}

body.night-mode .btn-primary:hover,
body.night-mode .btn-warning:hover,
body.night-mode .btn-secondary:hover {
  filter: brightness(1.3); /* Further lighten buttons in dark mode on hover */
}
