:root {
  /* Light Mode Variables */
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background-color: #f8f9fa;
  --card-border-color: #dddddd;
  --card-shadow-color: rgba(0, 0, 0, 0.1);
  --title-color: #343a40;
}

body.night-mode {
  /* Dark Mode Variables */
  --background-color: #121212;
  --text-color: #ffffff;
  --card-background-color: #1e1e1e;
  --card-border-color: #333333;
  --card-shadow-color: rgba(255, 255, 255, 0.1);
  --title-color: #f8f9fa;
}

.profile-container {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.profile-card {
  background-color: var(--card-background-color);
  border: 1px solid var(--card-border-color);
  box-shadow: 0 4px 8px var(--card-shadow-color);
  transition:
    background-color 0.3s,
    border-color 0.3s,
    box-shadow 0.3s;
}

.profile-title {
  color: var(--title-color);
  margin-bottom: 20px;
}

.profile-text {
  color: var(--text-color);
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .profile-title {
    font-size: 1.5rem;
  }

  .profile-text {
    font-size: 1rem;
  }
}
