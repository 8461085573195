/* Footer.css */

.footer {
  background-color: var(--navbar-footer-bg);
  color: var(--light-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: var(--light-shadow);
}

.footer-links a {
  color: var(--light-text);
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: var(--accent-color);
  text-decoration: underline;
}

.footer .social-icons i {
  font-size: 1.75rem;
  color: var(--light-text);
  transition: color 0.3s;
}

.footer .social-icons i:hover {
  color: var(--accent-color);
}

.footer p {
  font-size: 1rem;
  margin-bottom: 0;
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .footer-links a {
    font-size: 0.9rem;
  }

  .footer .social-icons i {
    font-size: 1.5rem;
  }

  .footer p {
    font-size: 0.9rem;
  }
}
