/* Forums.css */

/* Light and dark mode adaptation */
.forums-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.forums-title {
  color: var(--dark-text);
}

.forums-subtitle {
  color: var(--dark-text);
}

/* Card Styling */
.forum-card {
  background-color: var(--card-background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  border: 1px solid var(--border-color);
}

.forum-card .card-title {
  font-weight: 600;
}

.forum-card .card-text {
  margin-bottom: 1rem;
}

/* Button styling */
.forum-card .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.forum-card .btn-primary:disabled {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Dark mode adjustments */
body.night-mode .forums-container {
  background-color: var(--background-color-dark);
  color: var(--light-text);
}

body.night-mode .forums-title,
body.night-mode .forums-subtitle {
  color: var(--light-text);
}

body.night-mode .forum-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  border-color: var(--border-color-dark);
}

body.night-mode .forum-card .btn-primary:disabled {
  background-color: var(--secondary-color-dark);
  border-color: var(--secondary-color-dark);
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .forums-title {
    font-size: 1.75rem;
  }

  .forums-subtitle {
    font-size: 1rem;
  }
}
