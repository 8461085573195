/* Container Styling */
.terms-container {
  background-color: var(
    --background-color
  ); /* Background color adjusts with mode */
  color: var(--dark-text); /* Text color adjusts with mode */
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Card Styling */
.terms-card {
  background-color: var(
    --card-background-color-light
  ); /* Light mode background */
  color: var(--dark-text); /* Light mode text */
  box-shadow: var(--light-shadow); /* Light mode shadow */
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Dark Mode Adjustments */
body.night-mode .terms-container {
  background-color: var(--background-color); /* Dark mode background */
  color: var(--light-text); /* Dark mode text */
}

body.night-mode .terms-card {
  background-color: var(
    --card-background-color-dark
  ); /* Dark mode background */
  color: var(--light-text); /* Dark mode text */
  box-shadow: var(--heavy-shadow); /* Dark mode shadow */
}

/* Responsive Text Centering */
.text-center {
  text-align: center;
}

@media (max-width: 575.98px) {
  .terms-container {
    padding: 1rem;
  }

  .terms-card h3 {
    font-size: 1.25rem;
  }

  .terms-card p {
    font-size: 0.875rem;
  }

  .terms-container .text-center p {
    font-size: 0.875rem;
  }
}
