/* Clubs.css */

/* Base styles for the Clubs component */
.clubs-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Card styling */
.clubs-card {
  background-color: var(--card-background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  border: 1px solid var(--card-border-color);
}

/* Card title and text adjustments */
.clubs-card .card-title {
  color: var(--primary-color);
}

.clubs-card .card-text {
  color: var(--dark-text);
}

/* Heading and description styling */
.clubs-title {
  color: var(--primary-color);
}

.clubs-description {
  color: var(--dark-text);
}

/* Hover effects for the card */
.clubs-card:hover {
  box-shadow: var(--heavy-shadow);
}

/* Dark Mode Adjustments */
body.night-mode .clubs-container,
body.night-mode .clubs-card {
  background-color: var(--card-background-color-dark);
  color: var(--dark-text);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .clubs-title {
    font-size: 1.5rem;
  }
  .clubs-description {
    font-size: 1rem;
  }
}
