/* Benefits.css */

.benefits {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 2rem 0;
}

.benefits .card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: var(--light-shadow);
  border-radius: 8px;
}

/* Dark mode adjustments for the card */
body.night-mode .benefits .card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
}

.benefits .card h5 {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s;
}

.benefits .card p {
  font-size: 1rem;
  transition: color 0.3s;
}

.benefits .card i {
  color: var(--primary-color);
  transition: color 0.3s;
}

.benefits .card .shadow-sm {
  box-shadow: var(--light-shadow);
  transition: box-shadow 0.3s;
}

.benefits .card .shadow-sm:hover {
  box-shadow: var(--heavy-shadow);
}

/* Responsive styling */
@media (max-width: 767.98px) {
  .benefits .card h5 {
    font-size: 1.25rem;
  }

  .benefits .card p {
    font-size: 0.9rem;
  }

  .benefits .card i {
    font-size: 2rem;
  }
}
