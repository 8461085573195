/* BadgeManagement.css */

.badge-management-container {
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.badge-management-title {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.badge-management-table {
  background-color: var(--card-background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.badge-management-table th,
.badge-management-table td {
  border-color: var(--border-color);
}

.badge-management-delete-btn {
  background-color: var(--danger-color);
  border-color: var(--danger-color);
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.badge-management-delete-btn:hover {
  background-color: var(--danger-color-hover);
  border-color: var(--danger-color-hover);
}

/* Dark mode adjustments */
body.night-mode .badge-management-container {
  color: var(--light-text);
}

body.night-mode .badge-management-table {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
}

body.night-mode .badge-management-table th,
body.night-mode .badge-management-table td {
  border-color: var(--border-color-dark);
}

body.night-mode .badge-management-delete-btn {
  background-color: var(--danger-color-dark);
  border-color: var(--danger-color-dark);
}
