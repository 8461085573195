/* General Container and Section Styling */
.about-us-container {
  background-color: var(--background-color); /* Light or Dark Background */
  color: var(--dark-text); /* Light or Dark Text */
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  padding: 2rem;
}

/* Card Styles */
.about-us-card {
  background-color: var(--card-background-color-light); /* Light mode default */
  color: var(--dark-text);
  box-shadow: var(--light-shadow); /* Light mode default shadow */
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
  border-radius: 8px;
}

/* Dark mode adjustments */
body.night-mode .about-us-container {
  background-color: var(--background-color); /* Adjusted to dark background */
  color: var(--light-text); /* Adjusted to light text */
}

body.night-mode .about-us-card {
  background-color: var(
    --card-background-color-dark
  ); /* Dark mode card background */
  color: var(--light-text); /* Adjusted text color */
  box-shadow: var(--heavy-shadow); /* Dark mode shadow */
}

/* Hover effect for cards */
.about-us-card:hover {
  box-shadow: var(--heavy-shadow); /* Applies heavier shadow on hover */
}

/* Centered Text Styling */
.about-us-container h1,
.about-us-container p {
  color: var(--dark-text); /* Default dark text color */
  transition: color 0.3s ease;
}

body.night-mode .about-us-container h1,
body.night-mode .about-us-container p {
  color: var(--light-text); /* Adjust to light text in dark mode */
}

/* List Styles */
.about-us-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.about-us-container ul li {
  margin-bottom: 0.5rem;
}

/* PDF Section Styling */
.load-pdf-section {
  margin-top: 2rem;
}

.load-pdf-section h5 {
  color: var(--primary-color); /* Primary color */
  margin-bottom: 1rem;
}

.load-pdf-section a {
  color: var(--secondary-color); /* Secondary color */
  font-weight: 500;
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 575.98px) {
  .about-us-container {
    padding: 1rem;
  }
}
