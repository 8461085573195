/* UserManagement.css */

/* Container styles */
.user-management-container {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Table styles */
.user-table {
  background-color: var(--card-background-color);
  border-color: var(--border-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.user-table th,
.user-table td {
  border-color: var(--border-color);
}

/* Button styles */
.user-management-container .delete-button {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.user-management-container .delete-button:hover {
  background-color: var(--accent-color-hover);
  border-color: var(--accent-color-hover);
}

/* Night mode adjustments */
body.night-mode .user-management-container {
  background-color: var(--dark-background);
  color: var(--light-text);
}

body.night-mode .user-table {
  background-color: var(--dark-card-background);
  color: var(--light-text);
}

body.night-mode .user-table th,
body.night-mode .user-table td {
  border-color: var(--dark-border-color);
}

body.night-mode .delete-button {
  background-color: var(--dark-accent-color);
  border-color: var(--dark-accent-color);
}

body.night-mode .delete-button:hover {
  background-color: var(--dark-accent-color-hover);
  border-color: var(--dark-accent-color-hover);
}
