/* CenteredText.css */

/* General styling for the centered text */
.centered-text {
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  box-shadow: var(--light-shadow);
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Dark mode adjustments */
body.night-mode .centered-text {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  box-shadow: var(--heavy-shadow);
}
