/* MyLearningPage.css */

.my-learning-page {
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Headings */
h3 {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 20px;
  transition: color 0.3s;
}

/* Learning Cards */
.learning-card {
  background-color: var(--card-background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  border-radius: 8px;
  box-shadow: var(--light-shadow);
}

.learning-card:hover {
  box-shadow: var(--heavy-shadow);
}

.learning-card .card-title {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
  transition: color 0.3s;
}

.learning-card .card-text {
  color: var(--text-color);
  transition: color 0.3s;
}

/* Button Styles */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.btn-primary:hover {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.btn-secondary:hover {
  background-color: var(--secondary-hover-color);
  border-color: var(--secondary-hover-color);
}
