.career-paths {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.career-card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: var(--light-shadow);
}

body.night-mode .career-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  box-shadow: var(--light-shadow);
}

.career-card:hover {
  box-shadow: var(--heavy-shadow);
}

.career-card h5 {
  color: var(--primary-color);
}

.career-card p {
  color: var(--dark-text);
}

body.night-mode .career-card p {
  color: var(--light-text);
}
