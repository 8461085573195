/* Container Styles */
.career-path-details-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 2rem;
}

/* Card Styles */
.zoom-card {
  background-color: var(--card-background-color);
  color: var(--dark-text);
  box-shadow: var(--light-shadow);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  border-radius: 8px;
}

.zoom-card:hover {
  box-shadow: var(--heavy-shadow);
}

/* Image Styles */
.zoom-card img {
  border-radius: 8px;
}

/* Section Heading Styles */
.career-path-details-container h1,
.career-path-details-container h3,
.career-path-details-container h4,
.career-path-details-container h5 {
  color: var(--dark-text);
}

/* List Styles */
.career-path-details-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: var(--dark-text);
}

.career-path-details-container ul li {
  margin-bottom: 0.5rem;
}

/* Button Styles */
.career-path-details-container .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--light-text);
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;
}

.career-path-details-container .btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Intersection Observer Effects */
.zoom-card.in-view {
  transform: scale(1);
  opacity: 1;
}

.zoom-card.out-of-view {
  transform: scale(0.95);
  opacity: 0.8;
  transition:
    transform 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

/* Responsive Adjustments */
@media (max-width: 575.98px) {
  .career-path-details-container {
    padding: 1rem;
  }
}
