.pricing-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 2rem 0;
}

.pricing-card,
.contact-card,
.details-card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  box-shadow: var(--light-shadow);
  border-radius: 8px;
}

body.night-mode .pricing-card,
body.night-mode .contact-card,
body.night-mode .details-card {
  background-color: var(--card-background-color-dark);
}

.pricing-card h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
}

.pricing-card ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.pricing-card li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.pricing-card .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.pricing-card .btn-primary:hover {
  background: var(--primary-gradient);
  border-color: transparent; /* Match button border with the gradient */
}

.contact-card h5,
.details-card h4 {
  color: var(--primary-color);
}

.contact-card p,
.details-card p {
  font-size: 1rem;
}

.pricing-card:hover,
.contact-card:hover,
.details-card:hover {
  box-shadow: var(--heavy-shadow);
}

/* Responsive styling */
@media (max-width: 767.98px) {
  .pricing-card h2 {
    font-size: 2rem;
  }

  .pricing-card li {
    font-size: 0.9rem;
  }

  .contact-card p,
  .details-card p {
    font-size: 0.9rem;
  }
}
