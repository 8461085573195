/* Custom modal styling */
.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh; /* Ensures modal stays at 90% height */
}

.custom-modal .modal-content {
  height: 90vh; /* Ensures modal content is 90% of the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

/* Custom Close Button */
.custom-close-button .btn-close {
  background-color: black !important;
  opacity: 1; /* Ensure visibility */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style the close button's X */
.custom-close-button .btn-close::after {
  content: '×';
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes bounceText {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.animate-text {
  animation: bounce 1s infinite;
}

/* Dark Background Overlay for Modal */
.custom-backdrop {
  background: rgba(0, 0, 0, 0.8) !important; /* Dark overlay */
}

/* Dark Modal Body */
.modal-body-dark {
  background: #222;
  color: white;
  border-radius: 10px;
  padding: 20px;
}

.text-highlight {
  border: 5px solid #ffcc00; /* Bright yellow border for contrast */
  border-radius: 15px; /* Rounded edges for a smoother look */
  padding: 20px 40px; /* Padding for better spacing */
  display: inline-block; /* Keeps it compact */
  text-align: center; /* Ensures text is centered */
  box-shadow: 0px 4px 15px rgba(255, 204, 0, 0.7); /* Soft glowing effect */
}
