/* Page Layout */
.course-content-page {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.breadcrumb,
.breadcrumb-item a {
  color: var(--text-color);
}

/* Lesson Card */
.lesson-card {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* Interactive Exercise Section */
.exercise-card {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* Buttons */
button,
.btn-primary,
.btn-secondary {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  transition:
    background-color 0.3s,
    color 0.3s;
}

button:hover,
.btn-primary:hover,
.btn-secondary:hover {
  filter: brightness(0.9);
}

/* Syllabus, Resources, and Notes */
.syllabus-item,
.resource-item,
.note-item {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* Link Styling */
a {
  color: var(--text-color);
}

a:hover {
  color: var(--button-background-color);
  text-decoration: underline;
}
