.kids-learning-text {
  position: relative;
  display: inline-block;
  color: #ff4500; /* Fun Orange Color */
  font-weight: bold;
}

/* Sparkles Left */
.kids-learning-text::before {
  content: '⭐';
  position: absolute;
  top: 0;
  left: -5px;
  animation: sparkle 1.5s infinite ease-in-out;
}

/* Sparkles Right */
.kids-learning-text::after {
  content: '🌟';
  position: absolute;
  top: 0;
  right: -5px;
  animation: sparkle 1.5s infinite ease-in-out;
}

/* Sparkle Animation */
@keyframes sparkle {
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(1.2); }
  100% { opacity: 1; transform: scale(1); }
}

.sparkle-text {
    position: relative;
    display: inline-block;
    color: #007bff; /* Bootstrap Primary Color */
    font-weight: bold;
  }
  
  .sparkle-text::before {
    content: '✨';
    position: absolute;
    top: 0;
    left: -10px;
    animation: sparkle 1.5s infinite ease-in-out;
  }
  
  .sparkle-text::after {
    content: '✨';
    position: absolute;
    top: 0;
    right: -5px;
    animation: sparkle 1.5s infinite ease-in-out;
  }
  
  @keyframes sparkle {
    0%, 100% {
      opacity: 0;
      transform: scale(0.5) rotate(0deg);
    }
    50% {
      opacity: 1;
      transform: scale(1.2) rotate(45deg);
    }
  }
  