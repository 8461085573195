/* PrivacyPolicy.css */

/* Main container styling for both light and dark modes */
.privacy-policy-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Title styling */
.privacy-policy-title {
  color: var(--dark-text);
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Card styling */
.privacy-policy-card {
  background-color: var(--card-background-color);
  color: var(--dark-text);
  box-shadow: var(--light-shadow);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Dark mode adjustments */
body.night-mode .privacy-policy-container {
  background-color: var(--background-color-dark);
  color: var(--light-text);
}

body.night-mode .privacy-policy-title {
  color: var(--light-text);
}

body.night-mode .privacy-policy-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  border-color: var(--border-color-dark);
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .privacy-policy-title {
    font-size: 2rem;
  }
}
