/* Quiz Container */
.quiz-container {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Quiz Title */
.quiz-title {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Quiz Form */
.quiz-form {
  background-color: var(--card-background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--light-shadow);
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

/* Quiz Question */
.quiz-question {
  margin-bottom: 20px;
}

/* Quiz Options */
.quiz-option {
  color: var(--text-color);
}

/* Alert Styling */
.alert {
  background-color: var(--card-background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

/* Spinner Container */
.loading-container {
  height: 100vh;
}

/* Dark Mode Specific Adjustments */
body.night-mode .quiz-container {
  background-color: #181a1b;
  color: #e4e6eb;
}

body.night-mode .quiz-title {
  color: #1e90ff;
}

body.night-mode .quiz-form {
  background-color: #282a2d;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.3);
}

body.night-mode .quiz-option {
  color: #e4e6eb;
}

body.night-mode .alert {
  background-color: #282a2d;
  border-color: #4e4f50;
  color: #e4e6eb;
}
