:root {
  /* Light Mode Colors */
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background-color: #f8f9fa;
  --card-text-color: #333333;
  --title-color: #007bff;
  --border-color: #dddddd;
}

body.night-mode {
  /* Dark Mode Colors */
  --background-color: #121212;
  --text-color: #ffffff;
  --card-background-color: #1f1f1f;
  --card-text-color: #dddddd;
  --title-color: #1e90ff;
  --border-color: #333333;
}

.workspaces-container {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.workspace-title {
  color: var(--title-color);
  margin-bottom: 20px;
}

.workspace-card {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.workspace-card-title {
  color: var(--title-color);
}

.workspace-card-text {
  color: var(--card-text-color);
}

/* Hover effect for cards */
.workspace-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Smooth transitions for dark and light mode */
.workspace-card,
.workspace-title {
  transition:
    background-color 0.3s,
    color 0.3s;
}
