/* General Styles for Light and Dark Mode */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background-color: #f8f9fa;
  --card-text-color: #000000;
  --button-background-color: #007bff;
  --button-text-color: #ffffff;
  --border-color: #dddddd;
  --accordion-header-color: #007bff;
}

body.night-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --card-background-color: #1f1f1f;
  --card-text-color: #ffffff;
  --button-background-color: #1e90ff;
  --button-text-color: #ffffff;
  --border-color: #333333;
  --accordion-header-color: #1e90ff;
}

/* PublicCoursePage Layout */
.public-course-page {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Card Styles */
.card {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* Accordion Styles */
.accordion-button:not(.collapsed) {
  color: var(--accordion-header-color);
}

.accordion-body {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}

/* Button Styles */
button,
.btn-primary,
.btn-secondary {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  transition:
    background-color 0.3s,
    color 0.3s;
}

button:hover,
.btn-primary:hover,
.btn-secondary:hover {
  filter: brightness(0.9);
}

/* Activity Link Styles */
.activity-link {
  cursor: pointer;
  color: var(--text-color);
}

.activity-link:hover {
  color: var(--accordion-header-color);
  text-decoration: underline;
}

/* Spinner Styles */
.spinner-border {
  color: var(--accordion-header-color);
}
