/* LoadPDF.css */

/* General styling for the PDF viewer */
.pdf-container {
  text-align: center;
  height: 270vh; /* Adjust height to make it fully visible */
  overflow: hidden; /* Prevent scrolling */
  background-color: var(
    --background-color
  ); /* Adapt based on light/dark mode */
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Styling for the title */
.pdf-title {
  color: var(--dark-text); /* Adapt text color based on light/dark mode */
  margin-bottom: 1rem;
}

/* Styling for the iframe */
.pdf-iframe {
  border: none;
  object-fit: contain; /* Contain the entire PDF within the iframe */
}

/* Dark Mode Adjustments */
body.night-mode .pdf-title {
  color: var(--light-text); /* Switch text color in dark mode */
}

body.night-mode .pdf-container {
  background-color: var(
    --card-background-color-dark
  ); /* Dark mode background */
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .pdf-container {
    height: 200vh; /* Reduce height for smaller screens */
  }

  .pdf-title {
    font-size: 1.5rem;
  }
}
