/* General Layout */
.activity-page {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.activity-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.activity-loading-container {
  min-height: 80vh;
}

/* Sidebar */
.activity-sidebar {
  width: 300px;
  padding: 15px;
  background-color: var(
    --card-background-color-light
  ); /* Light background for better contrast */
  color: var(--dark-text); /* Ensures readable text color */
  border-right: 1px solid var(--border-color); /* Subtle border */
  box-shadow: var(--light-shadow); /* Adding a shadow for depth */
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
}

/* Accordion */
.activity-sidebar .accordion-item {
  background-color: var(
    --card-background-color-light
  ); /* Consistent with sidebar */
  border: 1px solid var(--border-color);
  margin-bottom: 10px; /* Space between accordion items */
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.activity-sidebar .accordion-header {
  font-weight: bold; /* Emphasize headers */
  color: var(--primary-color); /* Primary color for text */
  cursor: pointer;
}

.activity-sidebar .accordion-body {
  background-color: var(
    --card-background-color-dark
  ); /* Darker background for accordion content */
  padding: 10px;
  color: var(--light-text); /* Light text for contrast */
}

/* List Group */
.activity-sidebar .list-group-item {
  background-color: var(
    --card-background-color-light
  ); /* Match card background */
  color: var(--dark-text);
  border-radius: 5px; /* Rounded corners for items */
  margin-bottom: 5px; /* Space between items */
  transition:
    background-color 0.3s,
    color 0.3s;
}

.activity-sidebar .list-group-item:hover {
  background-color: var(--accent-color); /* Accent color on hover */
  color: var(--light-text); /* Text contrast on hover */
}

.activity-sidebar .list-group-item a {
  color: var(--primary-color); /* Make links stand out */
  font-weight: 500; /* Slightly bolder for emphasis */
}

.activity-sidebar .list-group-item a:hover {
  color: var(--accent-color);
}

/* Main Content */
.activity-main-content {
  background-color: var(--background-color);
  color: var(--text-color);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
  overflow: hidden; /* Disable scrolling */
}

.activity-content-container {
  flex-grow: 1;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  z-index: 1040;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Button */
.mark-complete-btn {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.mark-complete-btn:hover {
  background-color: var(--button-hover-background-color);
  color: var(--button-hover-text-color);
}
