.hero-section {
  margin-top: 2rem;
  text-align: center;
}

.hero-section .card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: var(--light-shadow);
}

body.night-mode .hero-section .card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  box-shadow: var(--light-shadow);
}

.hero-section .card.shadow-lg {
  box-shadow: var(--heavy-shadow);
}

.hero-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-section p.lead {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.hero-section .btn-primary {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
}

.hero-section .btn-light {
  font-size: 1.25rem;
}

.hero-section .shadow-sm {
  box-shadow: var(--light-shadow);
}

.hero-section .card-body h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-section .card-body p {
  font-size: 1rem;
}

.hero-section .bg-primary {
  background-color: var(--primary-color) !important;
}

.hero-section .text-white {
  color: var(--light-text) !important;
}

.hero-section .shadow-lg {
  box-shadow: var(--heavy-shadow);
}
