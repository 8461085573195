/* CustomModal.css */

/* General modal styling */
.custom-modal .modal-content {
  background-color: var(--background-color);
  color: var(--dark-text);
  border-radius: 8px;
  box-shadow: var(--light-shadow);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.custom-modal .modal-header {
  border-bottom: 1px solid var(--primary-color);
}

.custom-modal .modal-title {
  color: var(--dark-text);
}

.custom-modal .modal-body {
  color: var(--dark-text);
}

.custom-modal .modal-footer {
  border-top: 1px solid var(--primary-color);
}

/* Button styling */
.custom-modal .custom-button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.custom-modal .custom-button:hover {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

/* Dark Mode Adjustments */
body.night-mode .custom-modal .modal-content {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
}

body.night-mode .custom-modal .modal-title,
body.night-mode .custom-modal .modal-body {
  color: var(--light-text);
}

body.night-mode .custom-modal .modal-header,
body.night-mode .custom-modal .modal-footer {
  border-color: var(--primary-color-dark);
}

body.night-mode .custom-modal .custom-button {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
}

body.night-mode .custom-modal .custom-button:hover {
  background-color: var(--primary-color-hover-dark);
  border-color: var(--primary-color-hover-dark);
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .custom-modal .modal-title {
    font-size: 1.25rem;
  }
}
