/* CourseContentForm.css */

/* Container styling */
.course-content-form-container {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Monaco editor styling */
.monaco-editor {
  background-color: var(--editor-background);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Form styling */
.form-select,
.form-control {
  background-color: var(--input-background);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.form-select option {
  color: var(--text-color);
}

.form-label {
  color: var(--text-color);
}

/* Modal styling */
.modal-content {
  background-color: var(--card-background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Button styling */
.btn-primary,
.btn-success,
.btn-secondary {
  background-color: var(--button-background);
  border-color: var(--button-border);
  color: var(--light-text);
}

.btn-primary:hover,
.btn-success:hover,
.btn-secondary:hover {
  background-color: var(--button-background-hover);
  border-color: var(--button-border-hover);
}

/* Accordion styling */
.accordion-item {
  background-color: var(--card-background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.accordion-button {
  background-color: var(--accordion-header-background);
  color: var(--text-color);
}

.accordion-button:not(.collapsed) {
  background-color: var(--accordion-header-active);
}

/* Night mode adjustments */
body.night-mode .course-content-form-container {
  background-color: var(--dark-background);
  color: var(--light-text);
}

body.night-mode .monaco-editor {
  background-color: var(--editor-background-dark);
  color: var(--light-text);
}

body.night-mode .form-select,
body.night-mode .form-control {
  background-color: var(--input-background-dark);
  color: var(--light-text);
}

body.night-mode .accordion-item {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  border-color: var(--border-color-dark);
}

body.night-mode .accordion-button {
  background-color: var(--accordion-header-background-dark);
  color: var(--light-text);
}

body.night-mode .accordion-button:not(.collapsed) {
  background-color: var(--accordion-header-active-dark);
}
