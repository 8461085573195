/* AdminDashboard.css */

.admin-dashboard-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.admin-dashboard-card {
  background-color: var(--card-background-color);
  border: 1px solid var(--border-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  box-shadow: var(--light-shadow);
  border-radius: 8px;
  margin-bottom: 20px;
}

.admin-dashboard-card:hover {
  box-shadow: var(--heavy-shadow);
}

/* Dark mode adjustments */
body.night-mode .admin-dashboard-container {
  color: var(--light-text);
}

body.night-mode .admin-dashboard-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  border-color: var(--border-color-dark);
}

/* Button customization */
.admin-dashboard-card .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.admin-dashboard-card .btn-primary:hover {
  filter: brightness(0.9);
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .admin-dashboard-container {
    padding: 15px;
  }
}
