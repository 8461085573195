/* General Layout */
.ongoing-course-page {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.card {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.accordion-button {
  background-color: var(--accordion-header-background);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.accordion-button:not(.collapsed) {
  background-color: var(--accordion-header-active-background);
}

.accordion-body {
  background-color: var(--accordion-body-background);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Button Styles */
button,
.btn-primary,
.btn-danger,
.btn-secondary,
.btn-outline-success {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

button:hover,
.btn-primary:hover,
.btn-danger:hover,
.btn-secondary:hover,
.btn-outline-success:hover {
  filter: brightness(0.9);
}

/* Link Styles */
a {
  color: var(--link-color);
  transition: color 0.3s;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* ListGroup Items */
.list-group-item {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border-color: var(--border-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Dark Mode Variables */
body.night-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --card-background-color: #1f1f1f;
  --card-text-color: #ffffff;
  --border-color: #333333;
  --button-background-color: #1e90ff;
  --button-text-color: #ffffff;
  --link-color: #1e90ff;
  --link-hover-color: #63b3ed;
  --accordion-header-background: #1f1f1f;
  --accordion-header-active-background: #2c2c2c;
  --accordion-body-background: #2c2c2c;
}

/* Light Mode Variables */
body:not(.night-mode) {
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background-color: #f8f9fa;
  --card-text-color: #000000;
  --border-color: #dddddd;
  --button-background-color: #007bff;
  --button-text-color: #ffffff;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --accordion-header-background: #f8f9fa;
  --accordion-header-active-background: #e9ecef;
  --accordion-body-background: #ffffff;
}
