/* Container Styling */
.contact-page {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Titles and Subtitles */
.contact-title {
  color: var(--primary-color);
}

.contact-subtitle {
  color: var(--dark-text);
}

/* Card Styling */
.contact-info-card,
.contact-form-card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  box-shadow: var(--light-shadow);
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Dark Mode Adjustments */
body.night-mode .contact-page {
  background-color: var(--background-color);
  color: var(--light-text);
}

body.night-mode .contact-title {
  color: var(--primary-color);
}

body.night-mode .contact-subtitle {
  color: var(--light-text);
}

body.night-mode .contact-info-card,
body.night-mode .contact-form-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  box-shadow: var(--heavy-shadow);
}

/* Button Styling */
.contact-submit-btn {
  background-color: var(--primary-color);
  color: var(--light-text);
  border: none;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.contact-submit-btn:hover {
  background-color: var(--primary-color);
  filter: brightness(0.9);
}

/* Responsive Adjustments */
@media (max-width: 575.98px) {
  .contact-title {
    font-size: 1.5rem;
  }

  .contact-subtitle {
    font-size: 1rem;
  }

  .contact-heading {
    font-size: 1.25rem;
  }

  .contact-info-card,
  .contact-form-card {
    padding: 1rem;
  }
}
