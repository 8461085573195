/* Navbar Styles */
.navbar {
  background-color: var(--navbar-footer-bg-light); /* Default for light mode */
  color: var(--light-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Dark mode adjustments */
body.night-mode .navbar {
  background-color: var(--navbar-footer-bg-dark); /* Adjusted for dark mode */
  color: var(--light-text);
}

/* Button Styles */
.navbar .btn {
  background-color: transparent; /* Ensure buttons remain transparent */
  color: var(--light-text); /* Text color based on current mode */
  border: none;
  transition: color 0.3s;
}

/* Link Styles */
.navbar .nav-link {
  color: var(--light-text); /* Text color based on current mode */
  transition: color 0.3s;
}

/* Toggle Button Styles */
.navbar .navbar-toggler {
  border-color: var(--light-text); /* Border color matches text color */
}

/* Dropdown Menu Styles */
.navbar .dropdown-menu {
  background-color: var(
    --background-color
  ); /* Background color based on current mode */
  color: var(--dark-text); /* Text color based on current mode */
  transition:
    background-color 0.3s,
    color 0.3s;
}

body.night-mode .navbar .dropdown-menu {
  background-color: var(--background-color); /* Adjusted for dark mode */
  color: var(--light-text); /* Text color adjusted for dark mode */
}

/* Dropdown Item Styles */
.navbar .dropdown-item {
  color: var(--dark-text); /* Default text color for dropdown items */
  transition: color 0.3s;
}

body.night-mode .navbar .dropdown-item {
  color: var(--light-text); /* Adjusted text color for dark mode */
}
