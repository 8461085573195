/* Events.css */

/* Title and Subtitle Styling */
.events-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-color);
  transition: color 0.3s;
}

.events-subtitle {
  font-size: 1.25rem;
  color: var(--text-color);
  transition: color 0.3s;
}

/* Card Styling */
.events-card {
  background-color: var(--card-background-color);
  color: var(--text-color);
  box-shadow: var(--light-shadow);
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
}

.events-card:hover {
  box-shadow: var(--heavy-shadow);
}

.events-card-title {
  color: var(--text-color);
  font-weight: 600;
  transition: color 0.3s;
}

.events-card-text {
  color: var(--text-color);
  transition: color 0.3s;
}

/* Responsive Adjustments */
@media (max-width: 575.98px) {
  .events-title {
    font-size: 1.75rem;
  }

  .events-subtitle {
    font-size: 1.125rem;
  }
}
