/* LearnerDashboard.css */

.learner-dashboard {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.sidebar {
  background-color: var(--card-background-color);
  border-right: 1px solid var(--border-color);
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.sidebar .nav-link {
  color: var(--text-color);
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 4px;
  transition:
    color 0.3s,
    background-color 0.3s;
}

.sidebar .nav-link i {
  margin-right: 10px;
  font-size: 1.2rem;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background-color: var(--primary-color);
  color: var(--light-text);
}

.content {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 20px;
  border-left: 1px solid var(--border-color);
}

/* Mobile adjustments */
@media (max-width: 575.98px) {
  .sidebar {
    padding: 1rem;
  }

  .content {
    padding: 15px;
  }
}
