/* Navbar.css */

/* Navbar styling */
.custom-navbar {
  background-color: var(--navbar-footer-bg-light); /* Light mode default */
  color: var(--light-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  box-shadow: var(--light-shadow);
}

/* Dark mode adjustments */
body.night-mode .custom-navbar {
  background-color: var(--navbar-footer-bg-dark); /* Dark mode background */
  color: var(--light-text);
  box-shadow: var(--heavy-shadow);
}

/* Navbar brand and links */
.custom-navbar .navbar-brand,
.custom-navbar .nav-link {
  color: var(--light-text);
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s;
}

.custom-navbar .nav-link:hover {
  color: var(--accent-color); /* Accent color on hover */
}

/* Button styles */
.custom-navbar .btn-outline-light {
  border-color: var(--light-text);
  color: var(--light-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.custom-navbar .btn-outline-light:hover {
  background-color: var(--light-text);
  color: var(--navbar-footer-bg-light); /* Inverse color on hover */
}

body.night-mode .custom-navbar .btn-outline-light:hover {
  color: var(--navbar-footer-bg-dark); /* Adjusted for dark mode */
}

.custom-navbar .btn-link {
  color: var(--light-text);
}

.custom-navbar .btn-link:hover {
  color: var(--accent-color); /* Accent color on hover */
}

/* Toggle button styles */
.custom-navbar .navbar-toggler {
  border-color: var(--light-text);
}

.custom-navbar .navbar-toggler-icon {
  background-image: none; /* Custom icon color */
  color: var(--light-text);
  font-size: 1.25rem;
  line-height: 0;
}

/* Responsive adjustments */
@media (max-width: 575.98px) {
  .custom-navbar .navbar-brand {
    font-size: 1rem;
  }

  .custom-navbar .nav-link {
    font-size: 1rem;
  }
}
