/* Home.css */

.container-fluid {
  transition:
    background-color 0.3s,
    color 0.3s;
}

h3 {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 20px;
  transition: color 0.3s;
}

/* Card Styles */
.card {
  background-color: var(--card-background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  border-radius: 8px;
  box-shadow: var(--light-shadow);
}

.card:hover {
  box-shadow: var(--heavy-shadow);
}

.card-title {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
  transition: color 0.3s;
}

.card-text {
  color: var(--text-color);
  transition: color 0.3s;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: var(--light-text);
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.btn-primary:hover {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  color: var(--light-text);
}

/* Badge Styles */
.badge-warning {
  background-color: var(--accent-color);
  color: var(--light-text);
  font-weight: 500;
}

/* Link Styles */
.text-decoration-none {
  text-decoration: none;
}

.text-decoration-none:hover {
  text-decoration: none;
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.875rem;
  }
}
