
:root {
  /* Light Mode (Default) */
  /* --primary-color: #007bff;
  --secondary-color: #28a745;
  --accent-color: #ff5722;
  --background-color: #ffffff;
  --dark-text: #000000;
  --light-text: #ffffff;
  --navbar-footer-bg-light: #007bff;
  --card-background-color-light: #f8f9fa;
  --card-background-color-dark: #000000; */

  /* Gradients */
  --primary-gradient: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  --secondary-gradient: linear-gradient(135deg, #28a745 0%, #1e7e34 100%);
  --accent-gradient: linear-gradient(135deg, #ff5722 0%, #e64a19 100%);

  /* Shadows */
  --light-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  --heavy-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  --highlight-current-step: #a6e7a6;
  --highlight-previous-step: #fff8a6;
}

body.night-mode {
  /* --primary-color: #1e90ff;
  --secondary-color: #2ecc71;
  --accent-color: #ff4500;
  --background-color: #000000;
  --dark-text: #ffffff;
  --light-text: #000000;
  --navbar-footer-bg-dark: #1e90ff;
  --card-background-color-light: #1a1a1a;
  --card-background-color-dark: #f8f9fa; */

  /* Gradients */
  --primary-gradient: linear-gradient(135deg, #1e90ff 0%, #1c6ea4 100%);
  --secondary-gradient: linear-gradient(135deg, #2ecc71 0%, #27ae60 100%);
  --accent-gradient: linear-gradient(135deg, #ff4500 0%, #e62e00 100%);

  /* Shadows */
  --light-shadow: 0 2px 10px rgba(255, 255, 255, 0.3);
  --heavy-shadow: 0 4px 20px rgba(255, 255, 255, 0.5);

  --highlight-current-step: #1b5e20;
  --highlight-previous-step: orange;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--dark-text);
  background-color: var(--background-color);
  background-image: radial-gradient(var(--primary-color) 10%, transparent 11%);
  background-size: 20px 20px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Mobile Adjustments */
@media (max-width: 575.98px) {
  .custom-navbar h1 {
    font-size: 1.5rem;
  }

  .custom-navbar nav a {
    font-size: 1rem;
  }

  main {
    padding: 15px;
  }

  .sidebar {
    padding: 1.5rem;
  }
}
