/* Custom Modal Styling */
.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh; /* Keeps modal centered */
}

.custom-modal .modal-content {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 15px;
  padding: 20px;
  position: relative;
}

/* Dark Background Overlay */
.custom-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}

/* Dark Themed Modal Body */
.modal-body-dark {
  background: #222;
  color: white;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Centered Text Box */
.text-highlight {
  border: 5px solid #ffcc00;
  border-radius: 15px;
  padding: 20px 40px;
  display: inline-block;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(255, 204, 0, 0.7);
  position: relative;
  z-index: 1;
}

/* Finger Touch Animation */
@keyframes bounceTouch {
  0% { transform: translateY(0); }
  25% { transform: translateY(-50px); }
  50% { transform: translateY(-100x); }
  75% { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}

.finger-touch.bounce {
  animation: bounceTouch 0.5s ease-in-out;
}

/* Animated H1 (Fixed at Center) */
@keyframes bounceText {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}


/* Apply bounce animation while speaking */
.fixed-text.animate-text {
  animation: bounceText 1s infinite ease-in-out;
}

/* Responsive Fix for Small Screens */
@media (max-width: 768px) {
  .text-highlight {
    font-size: 3rem;
    padding: 15px 20px;
  }

  .finger-touch {
    font-size: 3rem;
    top: 45%;
  }

  .fixed-text {
    font-size: 6rem;
  }
}
