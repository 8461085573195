.featured-courses {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 2rem 0;
}

.course-card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  box-shadow: var(--light-shadow);
  border-radius: 8px;
  overflow: hidden;
}

body.night-mode .course-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  box-shadow: var(--light-shadow);
}

.course-card:hover {
  box-shadow: var(--heavy-shadow);
}

.course-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.course-card .card-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.course-card .card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.course-card .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.course-card .btn-primary:hover {
  background: var(--primary-gradient);
  border-color: var(--primary-gradient);
}

/* Responsive styling */
@media (max-width: 767.98px) {
  .featured-courses {
    padding: 1rem 0;
  }

  .course-card .card-title {
    font-size: 1.25rem;
  }

  .course-card .card-text {
    font-size: 0.9rem;
  }
}
