/* SuccessStories.css */

/* Container and Typography Styling */
.success-stories-container {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Card Styling for Light and Dark Modes */
.success-story-card {
  background-color: var(--card-background-color);
  color: var(--dark-text);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* Card Hover Effect */
.success-story-card:hover {
  box-shadow: var(--heavy-shadow);
}

/* Text Centering for Dark Mode */
body.night-mode .success-stories-container {
  color: var(--light-text);
}

body.night-mode .success-story-card {
  background-color: var(--card-background-color-dark);
  color: var(--light-text);
  border-color: var(--border-color-dark);
}

/* Placeholder Text Styling */
body.night-mode .text-center.mt-5 {
  color: var(--light-placeholder-text-color);
}

/* Responsive Styling */
@media (max-width: 575.98px) {
  .success-stories-container {
    padding: 15px;
  }
}
