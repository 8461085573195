/* CourseManagement.css */

/* Container styles */
.course-management-container {
  background-color: var(--background-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Table styles */
.course-table {
  background-color: var(--card-background-color);
  border-color: var(--border-color);
  color: var(--text-color);
  transition:
    background-color 0.3s,
    color 0.3s;
}

.course-table th,
.course-table td {
  border-color: var(--border-color);
}

/* Button styles */
.course-management-container .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.course-management-container .btn-danger {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.course-management-container .btn-primary:hover {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.course-management-container .btn-danger:hover {
  background-color: var(--accent-color-hover);
  border-color: var(--accent-color-hover);
}

/* Modal Header styles */
.modal-header.bg-success {
  background-color: var(--success-bg) !important;
}

.modal-header.bg-danger {
  background-color: var(--danger-bg) !important;
}

/* Night mode adjustments */
body.night-mode .course-management-container {
  background-color: var(--dark-background);
  color: var(--light-text);
}

body.night-mode .course-table {
  background-color: var(--dark-card-background);
  color: var(--light-text);
}

body.night-mode .course-table th,
body.night-mode .course-table td {
  border-color: var(--dark-border-color);
}
