.testimonials {
  background-color: var(--background-color);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s;
  padding: 2rem 0;
}

.testimonial-card {
  background-color: var(--card-background-color-light);
  color: var(--dark-text);
  transition:
    background-color 0.3s,
    color 0.3s,
    box-shadow 0.3s;
  box-shadow: var(--light-shadow);
  border-radius: 8px;
}

body.night-mode .testimonial-card {
  background-color: var(--card-background-color-dark);
}

.testimonial-card:hover {
  box-shadow: var(--heavy-shadow);
}

.testimonial-card blockquote {
  font-size: 1.1rem;
  font-style: italic;
}

.testimonial-card p {
  margin-bottom: 1rem;
}

.testimonial-card footer {
  font-size: 0.9rem;
  color: var(--primary-color);
  font-weight: bold;
}

@media (max-width: 767.98px) {
  .testimonial-card blockquote {
    font-size: 1rem;
  }

  .testimonial-card footer {
    font-size: 0.8rem;
  }
}
